import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import InputText from 'bloko/blocks/inputText';
import debounce from 'bloko/common/debounce';

import { useSelector } from 'lux/modules/useSelector';
import FilterIcon from 'src/components/FilterIcon';
import translation from 'src/components/translation';

import fetchAddressesList from 'src/pages/EmployerAddresses/components/fetchAddressesList';

const FILTER_DEBOUNCE = 400;

const EmployerAddressesFilter = ({ trls, isXs }) => {
    const dispatch = useDispatch();
    const query = useSelector(({ router }) => router.location.query);
    const filters = useSelector(({ employerAddressesPage }) => employerAddressesPage.filters);
    const loadingComponent = useSelector(({ employerAddressesPage }) => employerAddressesPage.loadingComponent);
    const [value, setValue] = useState(filters.searchText);

    const debouncedSearch = useMemo(
        () =>
            debounce((value) => {
                dispatch(
                    fetchAddressesList({
                        params: {
                            filters: { ...query, searchText: value },
                            loadingComponent: 'search',
                        },
                    })
                );
            }, FILTER_DEBOUNCE),
        [dispatch, query]
    );

    const onChange = (value) => {
        setValue(value);
        debouncedSearch(value);
    };

    const handleClear = () => {
        setValue('');
        dispatch(
            fetchAddressesList({
                params: {
                    filters: { ...query, searchText: '' },
                    loadingComponent: 'search',
                },
            })
        );
    };

    return (
        <InputText
            size={isXs ? undefined : '40'}
            placeholder={trls[EmployerAddressesFilter.trls.filterPlaceholder]}
            value={value}
            onChange={onChange}
            data-qa="employer-address-filter"
            icon={<FilterIcon isLoading={loadingComponent === 'search'} value={value} handleClear={handleClear} />}
        />
    );
};

EmployerAddressesFilter.propTypes = {
    trls: PropTypes.object,
    isXs: PropTypes.bool,
};

EmployerAddressesFilter.trls = {
    filterPlaceholder: 'employer.addresses.search',
};

export default translation(EmployerAddressesFilter);
