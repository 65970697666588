import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button, { ButtonKind } from 'bloko/blocks/button';
import FormError from 'bloko/blocks/form/FormError';
import { TrashScaleSmall, IconDynamic, IconLink, IconColor } from 'bloko/blocks/icon';
import Modal, { ModalFooter, ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import Radio from 'bloko/blocks/radio';
import RadioOpeningContent from 'bloko/blocks/radio/RadioOpeningContent';
import { SuggestLayer } from 'bloko/blocks/suggest';

import useToggleState from 'lux/hooks/useToggleState';
import { changeAddressesList, changeAddressesCount } from 'lux/models/employerAddressesPage';
import fetcher from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';
import AddressBlokoSuggest from 'src/components/AddressSuggest/AddressBlokoSuggest';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';

import employerAddressDeleted from 'src/pages/EmployerAddresses/components/EmployerAddressesNotification';
import { deleteAddress } from 'src/pages/EmployerAddresses/components/Utils';

const modalTitleDataQa = {
    0: 'address-remove-title-no-relation',
    1: 'address-remove-title-one-relation',
};

const modalTitle = {
    0: 'noAttachment',
    1: 'oneAttachment',
};

const WITH_REPLACE = 'withReplace';
const WITHOUT_REPLACE = 'withoutReplace';

const EmployerAddressesDelete = ({ trls, addressId }) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const employerId = useSelector(({ router }) => router.location.query.employerId);
    const remoteSearch = useSelector(({ addressesSuggestRemoteMode }) => addressesSuggestRemoteMode);
    const employerAddressesCount = useSelector(({ employerAddressesPage: { addressesCount } }) => addressesCount);
    const hasAddressesForReplace = employerAddressesCount > 1;
    const employerAddressesLeftOnPage = useSelector(({ employerAddressesPage: { addresses } }) =>
        addresses.filter((address) => address.id !== addressId)
    );
    const addressToDelete = useSelector(({ employerAddressesPage: { addresses } }) =>
        addresses.find((address) => address.id === addressId)
    );

    const [removeType, setRemoveType] = useState(WITHOUT_REPLACE);
    const [attachmentCount, setAttachmentCount] = useState(0);
    const [addressForReplace, setAddressForReplace] = useState(null);
    const [isModalVisible, toggleIsModalVisible] = useToggleState(false);

    const [suggestInput, setSuggestInput] = useState('');
    const [selectedItem, setSelectedItem] = useState({});
    const [showError, setShowError] = useState(false);
    const openModal = useCallback(async () => {
        toggleIsModalVisible();
        try {
            const { addressAttachmentCount } = await fetcher.get('/employer/addresses/delete', {
                params: { id: addressId },
            });
            setAttachmentCount(parseInt(addressAttachmentCount, 10));
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
        }
    }, [addNotification, addressId, toggleIsModalVisible]);

    const onRemoveTypeChange = (event) => {
        setRemoveType(event.target.value);
    };

    const onSubmit = async () => {
        if (showError) {
            return;
        }
        if (!addressForReplace && removeType === WITH_REPLACE) {
            setShowError(true);
            return;
        }
        try {
            await fetcher.postFormData('/employer/addresses/delete', {
                id: addressId,
                ...(removeType === WITHOUT_REPLACE ? {} : { newId: addressForReplace }),
            });
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
            return;
        }
        toggleIsModalVisible();
        addNotification(employerAddressDeleted);
        dispatch(changeAddressesList(employerAddressesLeftOnPage));
        dispatch(changeAddressesCount(employerAddressesCount - 1));
    };

    const selectAddress = (address) => {
        setSelectedItem(address);
        setSuggestInput(address.text);
        setShowError(!address.id);
        setAddressForReplace(address.id);
    };

    const onModalClose = useCallback(() => {
        setSuggestInput('');
        setSelectedItem({});
        setAddressForReplace(null);
        setRemoveType(WITHOUT_REPLACE);
        toggleIsModalVisible();
    }, [toggleIsModalVisible]);

    const openModalFromIframe = useCallback(
        ({ detail: id }) => {
            if (parseInt(id, 10) === addressId) {
                openModal();
            }
        },
        [addressId, openModal]
    );

    useEffect(() => {
        window.addEventListener('HH-AddressEdit-Remove-Clicked', openModalFromIframe);
        return () => {
            window.removeEventListener('HH-AddressEdit-Remove-Clicked', openModalFromIframe);
        };
    }, [openModalFromIframe]);

    return (
        <>
            <IconDynamic>
                <IconLink onClick={openModal} data-qa="address-item-delete" data-address-id={addressId}>
                    <TrashScaleSmall initial={IconColor.Gray50} highlighted={IconColor.Gray80} />
                </IconLink>
            </IconDynamic>
            <Modal visible={isModalVisible} onClose={onModalClose}>
                <ModalHeader>
                    <ModalTitle data-qa={modalTitleDataQa[attachmentCount] || 'address-remove-title-many-relations'}>
                        {trls[EmployerAddressesDelete.trls[modalTitle[attachmentCount] || 'manyAttachments']]}
                    </ModalTitle>
                </ModalHeader>
                <div className="address-popup-note address-text-container">{deleteAddress(addressToDelete)}</div>
                {!!attachmentCount && hasAddressesForReplace && (
                    <div className="address-popup-note address-delete-options">
                        <div className="form-box">
                            <Radio
                                name="removeType"
                                defaultValue={WITHOUT_REPLACE}
                                defaultChecked
                                onChange={onRemoveTypeChange}
                                data-qa="address-remove-radio-remove"
                            >
                                {trls[EmployerAddressesDelete.trls.deleteWithoutReplace]}
                            </Radio>
                        </div>
                        <div className="form-box">
                            <Radio
                                name="removeType"
                                defaultValue={WITH_REPLACE}
                                onChange={onRemoveTypeChange}
                                data-qa="address-remove-radio-replace"
                            >
                                <div className="form-box">{trls[EmployerAddressesDelete.trls.deleteWithReplace]}</div>
                                <RadioOpeningContent>
                                    <div className="address-select-container" data-qa="address-remove-addresses">
                                        <AddressBlokoSuggest
                                            addresses={employerAddressesLeftOnPage}
                                            address={selectedItem}
                                            inputValue={suggestInput}
                                            onSelect={selectAddress}
                                            selectOnBlur={!!suggestInput}
                                            searchOnFocus={!suggestInput}
                                            remoteSearch={remoteSearch}
                                            limit={6}
                                            placeholder={trls[EmployerAddressesDelete.trls.search]}
                                            invalid={showError}
                                            inputProps={{
                                                onChange: setSuggestInput,
                                            }}
                                            layer={SuggestLayer.AboveOverlayContent}
                                            addressIdNotToShow={addressId}
                                            employerId={employerId}
                                        />
                                        <FormError show={showError}>
                                            {trls[EmployerAddressesDelete.trls.addressRequired]}
                                        </FormError>
                                    </div>
                                </RadioOpeningContent>
                            </Radio>
                        </div>
                    </div>
                )}
                {!hasAddressesForReplace && trls[EmployerAddressesDelete.trls.removeNote]}
                <div>
                    <ModalFooter>
                        <Button kind={ButtonKind.Success} onClick={onSubmit} data-qa="address-remove-button">
                            {trls[EmployerAddressesDelete.trls.deleteAddress]}
                        </Button>
                    </ModalFooter>
                </div>
            </Modal>
        </>
    );
};

EmployerAddressesDelete.propTypes = {
    trls: PropTypes.object,
    addressId: PropTypes.number,
};

EmployerAddressesDelete.trls = {
    deleteAddress: 'contents.button.delete',
    deleteWithoutReplace: 'employer.address.remove_un',
    deleteWithReplace: 'employer.address.remove_changed',

    noAttachment: 'employer.address.remove_q',
    oneAttachment: 'employer.address.address_exist_one',
    manyAttachments: 'employer.address.address_exist',
    removeNote: 'employer.address.remove_note',

    search: 'employer.vacancy.VacancyCreateContent.workPlaceAddresses.search',
    addressRequired: 'employer.vacancy.VacancyCreateError.required',
};

export default translation(EmployerAddressesDelete);
