import { push } from 'connected-react-router';

import urlParser from 'bloko/common/urlParser';

import { changeAddressesLoadingStatus } from 'lux/models/employerAddressesPage';

export default ({ params }) =>
    async (dispatch) => {
        const loadingComponent = params.loadingComponent;
        const filters = { ...params.filters, page: 0 };

        dispatch(changeAddressesLoadingStatus(true, loadingComponent));
        dispatch(push(`/employer/addresses?${urlParser.stringify(filters)}`));
    };
